.Footer {
    background-color: rgb(12, 27, 47);
    display: flex;
    flex-direction: column;
}

.contacto {
    margin: auto;
    display: flex;
    height: 7vw;
    width: 65%;
}

.titulo {
    font-family: kimberley;
    font-size: 3vw;
    font-weight: normal;
    margin: auto auto 0 0;
    color: white;
}

.numero {
    margin: 0 auto 2% auto;
    height: max-content;
    width: 65%;
    display: flex;
}

.tel {
    margin: auto 1% auto auto;
    height: 2.5vw;
}

.ah2tel {
    text-decoration: none;
    width: max-content;
    height: max-content;
    margin: auto auto auto 1%;
    cursor: pointer;
}

@font-face {
    font-family: Swis;
    src: url("../../../Assets/fonts/Swis.ttf");
}

.h2tel {
    margin: 0;
    color: white;
    font-family: Swis;
    font-size: 2.5vw;
    font-weight: normal;

    &:hover {
        color: yellow;
    }
}

.escribenos {
    width: 65%;
    margin: 0 auto 1% auto;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.aHEscribenos {
    text-decoration: none;
    width: max-content;
    margin: auto;
}

.hEscribenos {
    color: white;
    font-family: swis;
    font-size: 2.5vw;
    font-weight: normal;
    margin: 1% auto 0 auto;
}

.flecha {
    margin: 0 auto;
    width: 25%;
    cursor: pointer;
}

.flechaV{
    margin: 0 auto;
    width: 8%;
    cursor: pointer;
    transform: rotate(180deg);
}

.redes {
    height: 8vh;
    width: 65%;
    margin: 0 auto;
    display: flex;
}

.hCopy {
    margin: auto auto 0.5% 0;
    color: white;
    font-weight: normal;
    font-size: 1.5vw;
}

.contLogos {
    width: 30%;
    height: 3vw;
    display: flex;
    margin: auto 0 0.5% auto;
    align-items: center;
    justify-content: center;
}

.aTiktok {
    width: 20%;
    height: 100%;
    margin: 0 auto 0 0;
    display: flex;
}

.tiktok {
    cursor: pointer;
    height: 100%;
}

.aInstagram {
    width: 20%;
    height: 100%;
    margin: auto;
    display: flex;
}

.instagram {
    height: 100%;
    margin: auto;
    cursor: pointer;
}

.aFacebook {
    width: 20%;
    height: 100%;
    margin: auto;
    display: flex;
}

.facebook {
    height: 90%;
    margin: auto;
    margin-bottom: 0;
    cursor: pointer;
}

.aWhatsapp {
    width: 20%;
    height: 100%;
    margin: auto;
    margin-right: 0;
    display: flex;
}

.whatsapp {
    height: 100%;
    margin: auto;
    cursor: pointer;
}




/*!--------------------------------------------------------------------------------------------------------*/




@media (max-width:1800px) {

    .contacto {
        width: 75%;
    }

    .numero {
        width: 75%;
    }

    .escribenos {
        width: 75%;
    }

    .redes {
        width: 75%;
    }
}

@media (max-width:1400px) {

    .contacto {
        width: 80%;
        height: 9vw;
    }

    .titulo {
        font-size: 4vw;
    }

    .numero {
        width: 80%;
    }

    .escribenos {
        width: 80%;
    }

    .redes {
        width: 80%;
    }
}

@media (max-width:1000px) {

    .contacto {
        height: 10vw;
    }

}

@media (max-width:800px) {

    .contacto {
        height: 12vw;
        width: 85%;
    }

    .titulo {
        font-size: 6vw;
    }

    .numero {
        margin-top: 2%;
        width: 85%;
    }

    .tel {
        margin: auto 1% auto auto;
        height: 4vw;
    }

    .h2tel {
        font-size: 3.5vw;
    }

    .escribenos {
        width: 85%;
    }

    .hEscribenos{
        font-size: 3.5vw;
    }

    .redes {
        width: 85%;
    }

    .contLogos {
        height: 5vw;
    }
}

@media (max-width:800px) {

    .numero{
        margin-top: 4vw;
    }

    .tel {
        height: 5vw;
    }

    .h2tel {
        font-size: 5vw;
    }
    .hEscribenos{
        font-size: 5vw;
    }
}