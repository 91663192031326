.Form{
    width: 100%;
    border: solid white 2px;
    border-radius: 10px;
}

.nombre{
    margin: 4% auto 2% auto;
    display: flex;
    width: 90%;
}

.hTitulo{
    margin: 0;
    color: white;
    font-weight: normal;
    font-family: swis;
    font-size: 2vw;
}

.input{
    width: 70%;
    font-size: 2vw;
    margin: 0 0 0 auto;
    border-radius: 8px;
}

.telefono{
    margin: 4% auto 2% auto;
    display: flex;
    width: 90%;
}

.correo{
    margin: 4% auto 2% auto;
    display: flex;
    width: 90%;
}

.mensaje{
    margin: 4% auto 4% auto;
    display: flex;
    width: 90%;
}

.textarea{
    width: 70%;
    font-size: 2vw;
    margin: 0 0 0 auto;
    border-radius: 8px;
    height: 14VW;
}

.enviar{
    margin: auto;
    width: 90%;
    display: flex;
}

.boton{
    background-color: white;
    font-size: 2vw;
    margin: 0 0 4% auto;
    cursor: pointer;
    color: rgb(12, 27, 47);
    border-radius: 5px;
    &:hover{
        background-color: rgb(0, 89, 255);
        color: white;
    }
}




/*!--------------------------------------------------------------------------------------------------------*/




@media (max-width:800px) {
    .nombre{
        flex-direction: column;      
    }

    .hTitulo{
        font-size: 4vw;
        margin: auto;
    }

    .input{
        font-size: 4vw;
        margin: auto;
        width: 95%;
    }

    .telefono{
        flex-direction: column;      
    }

    .correo{
        flex-direction: column;
    }
    .mensaje{
        flex-direction: column;
    }

    .textarea{
        margin: auto;
        width: 95%;
        font-size: 4vw;
    }

    .boton{
        font-size: 4vw;
    }
}