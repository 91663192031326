.Home {
    align-items: center;
}

.presentacion {
    background-image: url(../../Assets/img/Fondo.png);
    height: 100vh;
    background-size: cover;
    background-position: unset;
    display: flex;
}

.presentacion1 {
    height: 33%;
    width: 20%;
    margin: auto 0 auto auto;
    display: flex;
    flex-direction: column;
}

.logochip {
    margin: auto;
    margin-bottom: 1.5%;
    width: 90%;
}

.contLogos {
    width: 90%;
    height: 3vw;
    display: flex;
    margin: auto;
    margin-top: 1.5%;
    align-items: center;
    justify-content: center;
}

.aTiktok {
    width: 20%;
    height: 100%;
    margin: 0 auto 0 0;
    display: flex;
}

.tiktok {
    cursor: pointer;
    height: 100%;
}

.aInstagram {
    width: 20%;
    height: 100%;
    margin: auto;
    display: flex;
}

.instagram {
    height: 100%;
    margin: auto;
    cursor: pointer;
}

.aFacebook {
    width: 20%;
    height: 100%;
    margin: auto;
    display: flex;
}

.facebook {
    height: 90%;
    margin: auto;
    margin-bottom: 0;
    cursor: pointer;
}

.aWhatsapp {
    width: 20%;
    height: 100%;
    margin: auto;
    margin-right: 0;
    display: flex;
}

.whatsapp {
    height: 100%;
    margin: auto;
    cursor: pointer;
}

.presentacion2 {
    height: 33%;
    width: 45%;
    margin: auto auto auto 0;
    display: flex;
    flex-direction: column;
}

@font-face {
    font-family: arialnormal;
    src: url("../../Assets/fonts/arial.ttf");
}

.h3Creamos {
    color: white;
    margin: auto;
    margin-bottom: 2%;
    font-family: arialnormal;
    font-size: 1.6vw;
    font-weight: normal;
}

@font-face {
    font-family: kimberley;
    src: url("../../Assets/fonts/kimberley.otf");
}

.h1Pagina {
    color: yellow;
    font-family: kimberley;
    font-size: 4vw;
    margin: 2% auto;
    font-weight: normal;
}

.h3Impulsamos {
    color: white;
    margin: auto;
    margin-top: 2%;
    font-family: arialnormal;
    font-size: 1.8vw;
    font-weight: normal;
}

.servicios {
    background-color: rgb(236, 235, 235);
    height: 80vh;
    display: flex;
    flex-direction: column;
}

.servicios0 {
    height: 20%;
    width: 65%;
    margin: auto;
    margin-bottom: 0;
    display: flex;
}

.titulo {
    font-family: kimberley;
    font-size: 3vw;
    margin: 0 auto auto 0;
    color: rgb(12, 27, 47);
    font-weight: normal;
}

.contServicios {
    margin: auto;
    margin-top: 0;
    height: 50%;
    width: 65%;
    display: flex;
}

.servicios1 {
    height: 100%;
    width: 60%;
    margin: auto;
    margin-right: 0;
    display: flex;
    flex-direction: column;
}

.servicio {
    display: flex;
    margin: auto;
    margin-left: 0;
    width: 100%;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.check {
    width: 10vh;
    position: absolute;
    transform: translate(-2.5vh, -5vh) rotate(8deg);
}

.checkS {
    width: 4.5vh;
    position: absolute;
    transform: translate(0.3vh, -3.8vh) rotate(8deg);
    visibility: hidden;
}

.servicio:hover .checkS {
    visibility: visible;
}

.servicio:hover .check {
    visibility: hidden;
}

.servicios1:hover .check {
    visibility: hidden;
}

.cuadro {
    margin: auto 0.5vh;
    height: 1.5vw;
    width: 1.5vw;
    border: solid black 2px;
}

@font-face {
    font-family: lucon;
    src: url("../../Assets/fonts/lucon.ttf");
}

.h3servicio {
    margin: auto;
    margin-left: 6vh;
    font-family: lucon;
    font-size: 2.2vw;
    font-weight: normal;
}

.servicios2 {
    height: 80%;
    width: 40%;
    margin: auto;
    margin-left: 0;
    display: flex;
    border: solid black 2px;
    border-radius: 10px;
    background-color: white;
}

.imagenServicio {
    margin: auto;
    width: 90%;
    border-radius: 10px;
}

.aLogoWhatsapp {
    margin: 0;
    margin-left: auto;
    display: flex;
    height: 16%;
    width: min-content;
}

.logoWhatsapp {
    margin: 0 1vw auto auto;
    height: 90%;
}




/*!--------------------------------------------------------------------------------------------------------*/





@media (max-width: 1800px) {
    .presentacion1 {
        width: 25%;
    }

    .presentacion2 {
        width: 50%;
    }

    .h3Creamos {
        font-size: 2vw;
    }

    .h1Pagina {
        font-size: 4.5vw;
    }

    .h3Impulsamos {
        font-size: 2vw;
    }

    .servicios0 {
        width: 75%;
    }

    .contServicios {
        width: 75%;
    }
}

@media (max-height: 1000px) {
    .imagenServicio {
        width: auto;
        height: 90%;
    }
}

@media (max-width: 1400px) {
    .presentacion1 {
        width: 30%;
    }

    .presentacion2 {
        width: 60%;
    }

    .h3Creamos {
        font-size: 2.5vw;
    }

    .h1Pagina {
        font-size: 5vw;
    }

    .h3Impulsamos {
        font-size: 2.5vw;
    }

    .servicios0 {
        width: 80%;
    }

    .contServicios {
        width: 80%;
    }

    .titulo {
        font-size: 4vw;
    }

    .h3servicio {
        font-size: 2.8vw;
    }

    .imagenServicio {
        height: auto;
        width: 90%;
    }
}

@media (max-width: 1000px) {
    .presentacion {
        flex-direction: column;
    }

    .presentacion1 {
        margin: auto;
        margin-bottom: 0;
    }

    .presentacion2 {
        margin: auto;
        margin-top: 0;
    }

    .h3Creamos {
        font-size: 2.5vw;
    }

    .h1Pagina {
        font-size: 5vw;
    }

    .h3Impulsamos {
        font-size: 2.5vw;
    }
}

@media (max-height: 700px) {
    .imagenServicio {
        width: auto;
        height: 90%;
    }
}

@media (max-width: 800px) {
    .presentacion1 {
        width: 38%;
    }

    .contLogos {
        height: 13%;
    }

    .presentacion2 {
        width: 80%;
    }

    .h3Creamos {
        font-size: 3vw;
    }

    .h1Pagina {
        font-size: 6vw;
    }

    .h3Impulsamos {
        font-size: 3vw;
    }

    .servicios0 {
        width: 90%;
    }

    .titulo {
        font-size: 6vw;
    }

    .contServicios {
        width: 90%;
    }

    .servicios1 {
        height: 70%;
    }

    .cuadro {
        height: 2.8vw;
        width: 2.8vw;
    }

    .h3servicio {
        font-size: 4vw;
        margin-left: 6%;
    }

    .servicios2 {
        height: 60%;
    }
}

@media (max-height: 700px) {
    .imagenServicio {
        width: 90%;
        height: auto;
    }
}

@media (max-width: 600px) {

    .servicios0{
        height: 15%;
        width: 70%;
    }

    .titulo {
        font-size: 9vw;
    }

    .contServicios {
        flex-direction: column;
        height: 60%;
    }

    .servicios1 {
        margin: auto;
        width: 60%;
    }

    .check {
        width: 8vh;
        position: absolute;
        transform: translate(-2vh, -4vh) rotate(8deg);
    }

    .checkS {
        width: 3.8vh;
        position: absolute;
        transform: translate(0.1vh, -3.1vh) rotate(8deg);
    }

    .cuadro{
        margin-right: auto;
    }

    .h3servicio {
        font-size: 4.5vw;
        margin: auto 0;
    }

    .servicios2 {
        margin: auto;
        width: 50%;
    }

    .logoWhatsapp {
        height: 80%;
    }
}

@media (max-width: 400px) {
    .presentacion1 {
        width: 60%;
    }

    .contLogos {
        height: 11%;
    }

    .presentacion2 {
        width: 90%;
    }

    .h3Creamos {
        font-size: 4.3vw;
    }

    .h1Pagina {
        font-size: 9vw;
    }

    .h3Impulsamos {
        font-size: 4.3vw;
    }

    .servicios0{
        width: 70%;
    }

    .titulo{
        font-size: 11vw;
    }

    .contServicios{
        height: 70%;
    }

    .servicios1{
        height: 50%;
        width: 70%;
    }

    .aLogoWhatsapp{
        height: 10%;
    }
    .logoWhatsapp{
        height: 90%;
    }

    .servicios2{
        width: 70%;
        height: 40%;
    }
}